<template>
  <BaseCard>
    <h3>{ Education }</h3>
    <div class="content-card">
      <h4>Mbo 4 Software Developer</h4>
      <p class="company">Grafisch Lyceum Rotterdam</p>
      <p class="highlight">Minor: Front end</p>
    </div>
  </BaseCard>
</template>

<style scoped>
h3 {
  color: #50fff0;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
}

h4 {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.content-card {
  background-color: rgba(92, 77, 226, 0.1);
  border: 1px solid rgba(0, 140, 255, 0.144);
  border-radius: 12px;
  padding: 1.5rem;
}

.company,
.highlight {
  color: #50fff0;
  margin-bottom: 0.5rem;
}
</style>
