<template>
  <BaseCard>
    <h3>// Summary</h3>
    <p class="summary">
      A junior front-end developer with experience in Vue.js, Nuxt3, and
      TypeScript. Currently, I am interning at Robeco, where I work on
      challenging web projects and further develop my skills in web design and
      programming. I am eager to grow in my field and open to collaboration and
      new ideas!
    </p>
  </BaseCard>
</template>

<style scoped>
h3 {
  color: #50fff0;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
}

.summary {
  color: white;
  line-height: 1.6;
}
</style>
